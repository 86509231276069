import logo from './logo.svg';
import './App.css';
import Home from './Home';
import Header from './components/Header';
import Layout from './components/Layout';
import Qcall from './Qcall';
import { BrowserRouter,Routes, Route } from "react-router-dom";


function App() {
  return (
    <>
     <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/call" element={<Qcall />} />
            
          </Routes>
     </BrowserRouter>
   
    </>
  );
}

export default App;
