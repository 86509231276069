import React, { useState,useEffect } from 'react';
import { Helmet } from 'react-helmet';
//import fetchDataWithJWT from '../api/fetchDataWithJWT';
//import { useRouter } from 'next/router';
//import Link from 'next/link';


const Header = ({ onClick  }) => {
  useEffect(() => {
    // แก้ไข class ของ body เมื่อ MyComponent ถูก render
    document.body.classList.add('sidebar-collapse');

    // คืนฟังก์ชั่น cleanup ที่เรียกเมื่อ component ถูก unmount
    return () => {
      document.body.classList.remove('sidebar-collapse');
    };
  }, []); // เรียกใช้ useEffect แค่ครั้งเดียวเมื่อ component ถูก mount
 
    return (
      
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
<Helmet>
      <link async rel="stylesheet" href="/assets/front/stylesheet.css" />
      </Helmet>,
       
      {/*   <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"></a>
          </li>
        </ul>


        <div ><h4 className="title-header text-center">ระบบเรียกคิว โรงพยาบาลมายอ</h4></div>
        <ul className="navbar-nav ml-auto">
    
    
        
          <li className="nav-item">
            <a className="nav-link" data-widget="fullscreen" href="#" role="button">
              <i className="fas fa-expand-arrows-alt"></i>
            </a>
          </li>
        </ul> */}
      </nav>

    
  );
};

export default Header;