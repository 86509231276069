
import {React,useState,useEffect} from 'react'
import { Helmet } from 'react-helmet';
import io from 'socket.io-client';
import Moment from 'moment';
import { useLocation } from 'react-router-dom';


export default function Home() {
 

  const [qmessage, setQ] = useState('0');
  const getDefaultValues = () => {
    return { 'q_number': 0, 'q_chanel': 0 };
  };
  const [time, setTime] = useState(new Date());
  const [q_status, setStatus] = useState(true);
 // const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState([getDefaultValues(),getDefaultValues(),getDefaultValues()]);
  const location = useLocation();

  const date = new Date();

  var status_seb = false;
  var status_3 = false;
  var run_i  = 11;


  const result_date = date.toLocaleDateString('th-TH', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BASE_URL, {
      // ตั้งค่า debug: false เพื่อปิดการแสดง Log ทั้งหมด
      transports: ['websocket'],
    });

    const params = new URLSearchParams(location.search);
    // ใช้ get เพื่อดึงค่าที่ต้องการ, เช่น 'xx'
    const id = params.get('id');
    //socket.emit('q_status','go');
   
    const intervalId = setInterval(() => {
   
      setTime(new Date());
    }, 1000);
    
    // Event listener สำหรับรับข้อความใหม่
    socket.on('q_message', (msg) => {
      console.log('new message');
      //alert(id);
      setMessages([msg,...messages ]);
      if(id == null){
                socket.emit('q_status','wait');
                setStatus(false);
            
              
                var q_number = msg.q_number;
                var call_number = msg.q_number;
                var f_n = Array.from(q_number)[0];
                var f_l = Array.from(q_number)[1];
              
              console.log(messages);
                //if(q_number >=10){
                  /*   status_seb = true;
                  var f_n = Array.from(q_number)[0];
                  var f_l = Array.from(q_number)[1];
                  // รับตัวหลัง
                  if(f_l == 1){
                      var number_one = 'et.mp3';
                  }else{
                    var number_one = f_l+'.mp3';
                  }
                  //alert(f_n);
                  if(f_n == 1){
                    var  sib = "10.mp3";
                  }else if(f_n == 2){
                    var  sib = "20.mp3";
                  }else if(q_number == 100){
                    
                  }else{
                    status_3 =true;
                    var s1 = f_n;
                    var s1 = f_n;
                  }

                  call_number = 1;
                }else{
                  call_number = msg.q_number;
                }
                */
              // alert();
                
              var audio = new Audio('/assets/sound/aa.mp3');
              
                audio.play();
                var ch_status = 1;
                audio.addEventListener('ended', () => {
              
                  console.log('a');
                  console.log('b');
                //  console.log('Duration:', audio.duration); // ความยาวของเสียง
                  console.log(f_l);
                    if(q_number >= 30  && q_number < 100){
                      console.log('xxx30');
                        var audio4 = new Audio(`/assets/sound/`+f_n+`.mp3`);
                        audio4.play();
                        audio4.addEventListener('ended', () => {
                          var audio5 = new Audio(`/assets/sound/10.mp3`);
                          audio5.play();
                        
                          //หากเจอ  30 40 50 ...
                          if(f_l == 0){
                            // ช่องบริการ
                            audio5.addEventListener('ended', () => {
                                var audio6 = new Audio('/assets/sound/number.mp3');
                                audio6.play();
                                audio6.addEventListener('ended', () => {
                                  var audio7 = new Audio(`/assets/sound/`+msg.q_chanel+`.mp3`);
                                  audio7.play();
                                    //ค่ะ
                                    audio7.addEventListener('ended', () => {
                                      var audio8 = new Audio('/assets/sound/sur.mp3');
                                      audio8.play();
                                      setStatus(true);
                                    });
                                  
                                });

                            });
                          //ถ้าเจอ 1 2 3 4
                          }else{
                            if(f_l == 1){
                              f_l = 'et';
                            }
                            audio5.addEventListener('ended', () => {
                              var audio6 = new Audio(`/assets/sound/`+f_l+`.mp3`);
                              audio6.play();
                                  // ช่องบริการ
                            audio6.addEventListener('ended', () => {
                              var audio7 = new Audio('/assets/sound/number.mp3');
                              audio7.play();
                              audio7.addEventListener('ended', () => {
                                var audio8 = new Audio(`/assets/sound/`+msg.q_chanel+`.mp3`);
                                audio8.play();
                                  //ค่ะ
                                  audio8.addEventListener('ended', () => {
                                    var audio9 = new Audio('/assets/sound/sur.mp3');
                                    audio9.play();
                                    setStatus(true);
                                  });
                                
                              });

                          });
                              
                            
                            
                            });

                          
                          
                          }
                          
                        });
                      // หากเป็น 10 20
                    }else if(q_number >= 20  && q_number < 100){
                      console.log('xxx20');
                      
                          //หากเจอ  30 40 50 ...
                      if(f_l == 0){
                        var audio5 = new Audio(`/assets/sound/20.mp3`);
                        audio5.play();
                        audio5.addEventListener('ended', () => {
                                var audio6 = new Audio('/assets/sound/number.mp3');
                                audio6.play();
                                audio6.addEventListener('ended', () => {
                                  var audio7 = new Audio(`/assets/sound/`+msg.q_chanel+`.mp3`);
                                  audio7.play();
                                      //ค่ะ
                                      audio7.addEventListener('ended', () => {
                                      var audio8 = new Audio('/assets/sound/sur.mp3');
                                      audio8.play();
                                      setStatus(true);
                                    });
                                  
                                });

                            });
                        }else{
                          var audio5 = new Audio(`/assets/sound/20.mp3`);
                          audio5.play();
                          audio5.addEventListener('ended', () => {
                            if(f_l == 1){
                              f_l = 'et';
                            }

                            var audio6 = new Audio(`/assets/sound/`+f_l+`.mp3`);
                            audio6.play();
                            audio6.addEventListener('ended', () => {
                                var audio7 = new Audio('/assets/sound/number.mp3');
                                  audio7.play();
                                  audio7.addEventListener('ended', () => {
                                    var audio8 = new Audio(`/assets/sound/`+msg.q_chanel+`.mp3`);
                                    audio8.play();
                                        //ค่ะ
                                        audio8.addEventListener('ended', () => {
                                        var audio9 = new Audio('/assets/sound/sur.mp3');
                                        audio9.play();
                                        setStatus(true);
                                      });
                                    
                                  });
                            });
            
                        });

                        }
                      
                    }else if(q_number >=10 && q_number < 100 ){
                        //หากตัวหลังเท่ากับ 0 
                        if(f_l == 0){
                          
                        var audio5 = new Audio(`/assets/sound/10.mp3`);
                          audio5.play();
                          audio5.addEventListener('ended', () => {
                                  var audio6 = new Audio('/assets/sound/number.mp3');
                                  audio6.play();
                                  audio6.addEventListener('ended', () => {
                                    var audio7 = new Audio(`/assets/sound/`+msg.q_chanel+`.mp3`);
                                    audio7.play();
                                        //ค่ะ
                                        audio7.addEventListener('ended', () => {
                                        var audio8 = new Audio('/assets/sound/sur.mp3');
                                        audio8.play();
                                        setStatus(true);
                                      });
                                    
                                  });
            
                        });
                        
                        }else{
                            var audio5 = new Audio(`/assets/sound/10.mp3`);
                            audio5.play();
                            audio5.addEventListener('ended', () => {
                              if(f_l == 1){
                                f_l = 'et';
                              }

                              var audio6 = new Audio(`/assets/sound/`+f_l+`.mp3`);
                              audio6.play();
                              audio6.addEventListener('ended', () => {
                                  var audio7 = new Audio('/assets/sound/number.mp3');
                                    audio7.play();
                                    audio7.addEventListener('ended', () => {
                                      var audio8 = new Audio(`/assets/sound/`+msg.q_chanel+`.mp3`);
                                      audio8.play();
                                          //ค่ะ
                                          audio8.addEventListener('ended', () => {
                                          var audio9 = new Audio('/assets/sound/sur.mp3');
                                          audio9.play();
                                          setStatus(true);
                                        });
                                      
                                    });
                              });
              
                          });
                        }
                      }else if(q_number >=100 ){
                          console.log(q_number);

                          var audio5 = new Audio(`/assets/sound/1.mp3`);
                          audio5.play();
                          audio5.addEventListener('ended', () => {
                            var audio6 = new Audio('/assets/sound/h.mp3');
                            audio6.play();
                            audio6.addEventListener('ended', () => {
                                var audio7 = new Audio('/assets/sound/number.mp3');
                                audio7.play();
                                audio7.addEventListener('ended', () => {
                                  var audio8 = new Audio(`/assets/sound/`+msg.q_chanel+`.mp3`);
                                  audio8.play();
                                      //ค่ะ
                                      audio8.addEventListener('ended', () => {
                                      var audio9 = new Audio('/assets/sound/sur.mp3');
                                      audio9.play();
                                      setStatus(true);
                                    });
                                  
                                });
                            });
                          });
                      
                      //ถ้าไม่ถึง 10
                      }else{

                        var audio2 = new Audio(`/assets/sound/`+call_number+`.mp3`);
                        audio2.play();
                        audio2.addEventListener('ended', () => {
                          var audio3 = new Audio('/assets/sound/number.mp3');
                          audio3.play();
                          audio3.addEventListener('ended', () => {
                                  var audio4 = new Audio(`/assets/sound/`+msg.q_chanel+`.mp3`);
                                  audio4.play();
                                  audio4.addEventListener('ended', () => {
                                    var audio5 = new Audio('/assets/sound/sur.mp3');
                                    audio5.play();
                                    setStatus(true);
                                  });

                    
                                  var audio4 = new Audio(`/assets/sound/`+msg.q_chanel+`.mp3`);
                                  audio4.play();
                                  //ค่ะ
                                  audio4.addEventListener('ended', () => {
                                    var audio5 = new Audio('/assets/sound/sur.mp3');
                                    audio5.play();
                                    setStatus(true);
                                    //socket.emit('q_status','go');
                                  });
                                });
                          });
                                

                      }
                    
                    /*   audio4.addEventListener('ended', () => {
                        var audio5 = new Audio('/assets/sound/sur.mp3');
                        audio5.play();
                      }); */
                      
                });
        }
     
     // setQ(msg);
     
    });

    console.log('tt');
    console.log(q_status);
    if(q_status == true && id == null){
      socket.emit('q_status','go');
    }
    console.log('tt');

    // คืนฟังก์ชั่น cleanup เมื่อ component ถูก unmount
    return () => {
      clearInterval(intervalId);
      socket.disconnect();
    };


  }, [messages,q_status,location.search]);


  return (
    <>
     <Helmet>
           <link async rel="stylesheet" href="/assets/front/stylesheet.css" />
     </Helmet>
    <div className="header">
  
   <div className="inline">
       <div className="div-logo inline"><img width="250" src="/assets/images/logomayo.png" alt=""/></div>
       
   

   </div>
   <div className="div-title inline">คิวรอรับยา</div>
   <div className="inline" style={{float:'right',marginTop: '15px',marginRight:'15px'}}>

       <div className="inline div-date">{result_date}</div>
       <div className="inline div-time"><span id="txt">{Moment(time).format('H:m:s')}</span></div>

   </div>
</div>

     <div className="wrapper">
      <div className="section-1">  
          <div className="">
              <div className="div2-top-1">ช่องบริการ</div>
              <div className="div3-top-1">หมายเลข</div>
          </div>
          <div id="list-patient-1">
          {messages.slice(0, 3).map((msg, index) => {
            if(msg.q_number > 0 && index == 0){
              var class_q = "que-warning";
            }else{
              var class_q = "que-warning-none"; 
            }
            
                return (
                    <div className="box " key={index}>
                        <div className="div2"><span className='que-warning-none'>{msg.q_chanel}</span></div>
                        <div className="div1"><span className={class_q}>{msg.q_number}</span></div>
                  </div>
                )
           })}

          </div>
      </div> 
      </div>

    </>
  )
}
