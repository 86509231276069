// pages/about.js (หรือ about.tsx ถ้าใช้ TypeScript)
import Layout from './components/Layout';
//import '../../styles/globals.css'
//import Link from 'next/link';
import React,{useState,useEffect,useRef} from 'react';
import fetchDataWithJWT from './api/fetchDataWithJWT';
//import Head from 'next/head';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { useRouter } from 'next/router';
import { useNavigate,useParams } from 'react-router-dom';
import io from 'socket.io-client';
import Swal from 'sweetalert2';

const Q = () => {
  const socketRef = useRef(null);
  //const router = useRouter();

  const { id } = useParams();
  const router = useNavigate();
  const BASE_URL =  process.env.REACT_APP_BASE_URL
  const SECRET_KEY = process.env.SECRET_KEY;
  const token = process.env.TOKEN_KEY;
  const [q_status, setStatus] = useState(true);

  const getDefaultValues = () => {
    return { 'q_number': 0, 'q_chanel': 0 };
  };
  const [messages, setMessages] = useState([getDefaultValues(),getDefaultValues(),getDefaultValues()]);
  //const socket = io('http://localhost:11426', { path: '/call/socket.io' });

  
 
  
  const [formData, setFormData] = useState({
    q_number: '1',
    q_chanel: '1',
    groupID: "",
  });
  const [errors, setErrors] = useState({});

useEffect(() => {
  socketRef.current = io(process.env.REACT_APP_BASE_URL, {
    // ตั้งค่า debug: false เพื่อปิดการแสดง Log ทั้งหมด
    transports: ['websocket'],
  });
  

  socketRef.current.on('q_status', (msg) => {
    console.log(msg);
     if(msg == 'wait'){
  
      setStatus(false);
     }else{
      setStatus(true);
     }
   });
   socketRef.current.on('q_message', (msg) => {
    setMessages([msg,...messages ]);
   });

 
 
  
 // Cleanup function: ปิดการเชื่อมต่อเมื่อ component unmount
 return () => {
  if (socketRef.current) {
    socketRef.current.disconnect();
  }
};

}, []);




  const handleSubmit = async (event) => {
   
   
    event.preventDefault();
    


     if(q_status == false){
            Swal.fire({
              title: '',
              text: 'โปรดรอเสียงจบก่อนค่ะ !!',
              icon: 'warning',
              confirmButtonText: 'OK'
            });
            return null;
     }
    // เช็คความถูกต้องของข้อมูล
    let newErrors = {};
    console.log(formData);
    //console.log(formData);
    //if (!formData.username) {
      //newErrors.username = 'Please enter your Username.';

    //}

   // if (!formData.password) {
    //  newErrors.password = 'Please enter your password.';
     
   // }

    if (Object.keys(newErrors).length === 0) {
       socketRef.current.emit('q_message',{
          'q_number':formData.q_number,
          'q_chanel':formData.q_chanel
        });
      /*   var audio = new Audio('/assets/sound/aa.m4a');
        audio.play();
        audio.addEventListener('ended', () => {
          console.log('Audio has finished playing.');
          console.log('Duration:', audio.duration); // ความยาวของเสียง
          var soun_dur1 = audio.duration;
          var audio2 = new Audio('/assets/sound/1.m4a');
          audio2.play();
          audio2.addEventListener('ended', () => {
            var audio3 = new Audio('/assets/sound/t.m4a');
            audio3.play();
            audio3.addEventListener('ended', () => {
              var audio4 = new Audio('/assets/sound/2.m4a');
              audio4.play();
              audio4.addEventListener('ended', () => {
                var audio5 = new Audio('/assets/sound/sur.m4a');
                audio5.play();
              });
            });
          });
        });
        */
        
       

/* 
        
        var audio = new Audio('/assets/sound/1.m4a');
        audio.current.addEventListener('ended', () => {
          audio.current.play();
        });
      
       */
       // alert('ok');
  
    }

    setErrors(newErrors);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // ตรวจสอบว่าค่าที่กรอกเข้ามาอยู่ในช่วงที่ต้องการหรือไม่
    if (value < 1) {
    //   var data = 1;
    } else if (value > 100) {
   //   var data = 100;
    }else{
     //  var data = value;
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    // เมื่อมีการคีย์ใหม่เกิดขึ้นให้ทำการลบข้อความข้อผิดพลาดที่เกี่ยวข้องออก
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const incrementNumber = () => {
    //setNumber(number + 1);
    var setN = parseInt(formData.q_number)+1;
    setFormData({
      ['q_number']: setN,
    });

  };

  const decrementNumber = () => {
    if (formData.q_number > 0) {
      var setN = parseInt(formData.q_number)-1;
      setFormData({
        ['q_number']: setN,
      });
    }
  };

  return (
    <>
 <Layout>
          <div className="content-wrapper">
                    <div className="content-header">
                    <div className="container-fluid">
                      <div className="row mb-2">
                        <div className="col-sm-6">
                          <h4 className="m-0"></h4>
                        </div>
                      
                      
                      </div>
                    </div>
                  </div>
                  {/* Main content */}
                  <section className="content">
                    <div className="container-fluid">
                      {/* Input addon */}
                        <div className="card card-info">
                          <div className="card-header ">
                            <h3 className="card-title text-center" >เรียกคิว</h3>
                          </div>
                          <div className="card-body">
                      
                          <form className="w-full max-w-lg" onSubmit={handleSubmit}>
                              <input type='hidden' id="groupID"  name="groupID"   value={formData.groupID} />
                              <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    คิวปัจจุบัน
                                  </label>
                                  <div className="flex items-center">
                                       {/*  <button type="button"  style={{marginTop: "5px"}}  className="stepper-sub bg-gray-300 text-gray-700 font-bold py-3  px-3 rounded-l" onClick={decrementNumber}>-</button>  */}
                                        <input type="number"   min={1}  max={100}  id="q_number"  name="q_number"   value={formData.q_number}  onChange={handleChange}  
                                        className="appearance-none block w-full  text-gray-700 border border-gray-200  py-3 px-3  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  />
                                       {/*  <button type="button"  style={{marginTop: "5px"}}  className="stepper-add bg-gray-300 text-gray-700 font-bold py-3 px-3   rounded-r"  onClick={incrementNumber}>+</button>  */}
                                  </div>
                                 </div>
                              </div>
                              <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                  ช่องที่
                                  </label>
                                  <select id="q_chanel" value={formData.q_chanel} name="q_chanel"  onChange={handleChange}   className="border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                  </select>
                                 
                                </div>
                              </div>
                              <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3">
                                <button type="submit" className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"><i className='fas fa-bullhorn'></i> เรียกคิว</button>
                               
                                </div>
                              </div>
                             
                             
                            </form>
                            

                            <div className="wrapper">
                                <div className='title-q'>คิวเรียกล่าสุด</div>
                                <div className="section-1">  
                                  
                                    <div className="">
                                        <div className="div2-top-1"  style={{fontSize: "16px"}}>ช่องบริการ</div>
                                        <div className="div3-top-1"   style={{fontSize: "16px"}}>หมายเลข</div>
                                    </div>
                                    <div id="list-patient-1"   style={{fontSize: "16px"}}>
                                    {messages.slice(0, 1).map((msg, index) => {
                                      if(msg.q_number > 0 && index == 0){
                                        var class_q = "que-warning";
                                      }else{
                                        var class_q = "que-warning-none"; 
                                      }
                                      
                                          return (
                                              <div className="box " key={index}>
                                                  <div className="div2"   style={{fontSize: "16px"}}><span className='que-warning-none'   style={{fontSize: "16px"}}>{msg.q_chanel}</span></div>
                                                  <div className="div1"   style={{fontSize: "16px"}}><span className={class_q}   style={{fontSize: "16px"}}>{msg.q_number}</span></div>
                                            </div>
                                          )
                                    })}

                                    </div>
                                </div> 
                            </div>

                          </div>


                        {/* /.card-body */}
                    </div>
                  </div>

                  
                    {/* /.container-fluid */}
                  </section>
              </div>
              

             
     </Layout>
    </>
  );
};

export default Q;