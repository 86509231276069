import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
//import '../../styles/globals.css'

const Layout = ({ children,onClick }) => {
  return (
    <>
    <Helmet>
     
    <link async rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback" />
    <link rel="stylesheet" href="/assets/plugins/fontawesome-free/css/all.min.css" />
    <link async rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" />

    <link async rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css" />

    <link async rel="stylesheet" href="/assets/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css" />

    <link async rel="stylesheet" href="/assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css" />

    <link async rel="stylesheet" href="/assets/plugins/jqvmap/jqvmap.min.css" />

    <link async rel="stylesheet" href="/assets/dist/css/adminlte.css" />

    <link async rel="stylesheet" href="/assets/css/custom.css" />
    {/*  <script async src="/assets/plugins/jquery/jquery.min.js"></script> */}
 
    <link async href="https://fonts.googleapis.com/css2?family=Prompt" rel="stylesheet"></link>
   {/*  <script async src="/assets/dist/js/adminlte.js"></script> */}
   <script src="https://cdn.socket.io/4.0.0/socket.io.min.js"></script>
    </Helmet>

    <div x-data="setup()" x-init="$refs.loading.classList.add('hidden'); setColors(color);" className='dark'>
      <div className="flex h-screen antialiased text-gray-900 bg-gray-100 dark:bg-dark dark:text-light" >
        {/*  Loading screen */}
      
      

        <div className="flex-1 h-full overflow-x-hidden overflow-y-auto">
          {/*  Navbar */}
          <Header />
          {/*  Main content */}
          <main>{children}</main>
             {/*   <main>

            <div className="flex items-center justify-between px-4 py-4 border-b lg:py-6 dark:border-primary-darker">
              <h1 className="text-2xl font-semibold">Dashboard</h1>
           
            </div>

       
          </main> */}

          {/*  Main footer */}
   
        </div>

      
      </div>
    </div>


      

    
      {/* ตัวอย่างเนื้อหาเพิ่มเติม */}
    </>
  );
};

export default Layout;